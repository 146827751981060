import { get, omit, set, unset, values, without } from 'lodash';
import { Injectable } from '@angular/core';
import { Command, Random, Tracker } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from 'apps/nucleus/src/base/diagram/diagram-change.svc';
import { AbstractShapeModel, TextFormatter } from 'flux-diagram-composer';
import { DataItemLevel } from 'apps/nucleus/src/base/edata/model/edata.mdl';
import * as innerText from '@creately/inner-text';
import {
    TiptapDocumentsManager,
} from 'apps/nucleus/src/base/ui/shape-data-editor/tiptap-documents-manager.cmp';
import { TiptapHelper } from 'apps/nucleus/src/framework/ui/components/tiptap/tiptap-helper';
import { DESCRIPTION_DATAITEM_ID } from 'apps/nucleus/src/base/ui/shape-data-editor/data-items-renderer.cmp';
import { TiptapDocumentsManagerShapeText } from 'apps/nucleus/src/base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { DEFUALT_TEXT_STYLES, DataType } from 'flux-definition/src';
import { getTextDirection } from 'tiptap-text-direction';
import { coreDataDefs } from '../../../base/data-defs';
import { TiptapDocumentsManagerEdata } from 'apps/nucleus/src/base/ui/shape-data-editor/tiptap-documents-manager-edata.cmp';

/**
 * ChangeShapeDataItems
 * This commad is to add/update/remove data items from shapes
 * Only optional data items can be removed.
 */
@Injectable()
@Command()
export class ChangeShapeDataItems extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        [shapeId: string]: {
            [path: string]: {
                value?: any,
                label?: string,
                isNested?: boolean,
                optional?: boolean,
                isCore?: boolean,
            },
        },
    };

    protected textFormatter: TextFormatter;

    constructor( protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
        this.textFormatter = new TextFormatter();
    }

    public prepareData() {
        for ( const shapeId in this.data ) {
            const dataItems = this.data[ shapeId ];
            const shape = this.changeModel.shapes[ shapeId ];
            if (( shape as any ).initialNotes ) {
                delete ( shape as any ).initialNotes;
            }

            const innerSelection = Object.values( shape.data )
                .filter( d => d.type === DataType.CHILD_SHAPE && !!d.value.selected )
                .map( d => d.value.id );

            for ( const path in dataItems ) {
                if ( path === DESCRIPTION_DATAITEM_ID ) {
                    this.updatePrimaryText( shape );
                }
                const dataItem = dataItems[ path ];
                if ( dataItem && innerSelection?.length > 0 ) {
                    const ids = new Set<string>([
                        ...(( dataItem as any ).innerSelection || []),
                        ...innerSelection,
                    ]);
                    ( dataItem as any ).innerSelection  = Array.from( ids );
                }
                this.trackDescriptionDataitem( path, shape, dataItem?.value );
                this.setSettingsDataItem( shape, dataItem );
                const shapeDI = get( shape.data, path );
                if ( shape.data && shapeDI && dataItem && dataItem.value !== undefined ) {
                    if ( !dataItem.isNested ) {
                        set( shape.data, `${path}.value`, dataItem.value );
                        if ( dataItem.isCore ) {
                            without( Object.keys( dataItem ), 'value', 'isCore' ).forEach( key => {
                                set( shape.data, path + key, dataItem[ key ]);
                            });
                        }
                    } else {
                        set( shape.data, path, dataItem.value );
                    }
                }
                // Add new data item
                if ( shape.data && !shapeDI && dataItem && dataItem.value !== undefined ) {
                    if ( !dataItem.isNested ) {
                        if ( dataItem.isCore ) {
                            set( shape.data , path, omit( dataItem, [ 'isCore' ]));
                        } else {
                            set( shape.data , path, { value: dataItem.value });
                        }
                    } else {
                        set( shape.data , path, dataItem.value );
                    }
                }
                // Remove data item
                if ( shape.data && shapeDI && !dataItem ) {
                    unset( shape.data , path );
                }
                const isCore = dataItem?.isCore || coreDataDefs[ path ];
                if ( !isCore ) {
                    this.changeModel.updateDataDefs( shapeId, path, dataItem, DataItemLevel.DataDef );
                }
            }

        }
    }

    /**
     * If the settings data item is not set, it should be auto updated to show the
     * descriptipn
     */
    protected setSettingsDataItem( shape, dataItem ) {
        if ( shape.data && !shape.data.dataitemsttings && dataItem && dataItem.id === 'description' ) {
            if ( !this.isDescriptionEmpty( dataItem.value )) {
                shape.data.dataitemsttings = {
                    id: 'dataitemsttings',
                    def: 'descriptionRichtext',
                    value: { ops: [{ insert: `{{Description}}` }]},
                };
            }
        }
    }

    protected updatePrimaryText( shape: AbstractShapeModel ) {
        const primaryText = shape.primaryTextModel;
        if ( primaryText ) {
            let textNode;
            if ( shape.eDataId && shape.entityId ) {
                textNode = TiptapDocumentsManagerEdata.getPrimaryTextNode( shape.eDataId, shape.entityId );
            } else {
                textNode = TiptapDocumentsManager.getPrimaryTextNode( this.changeModel.id, shape.id );
            }
            if ( textNode.domNode ) {
                if ( !textNode.details ) { // Uniform text, can be carota or tiptap
                    primaryText.rendering = getTextDirection( textNode.node.textContent ) === 'rtl' ? 'tiptapCanvas' : 'carota';
                    if ( primaryText.rendering === 'tiptapCanvas' ) {
                        primaryText.html = TiptapHelper
                            .updateUniformFormatWithNoLineBreaks( primaryText.html, textNode.title );
                    } else {
                        primaryText.content = [{
                            ...DEFUALT_TEXT_STYLES,
                            ...primaryText.content[0],
                            text: textNode.node.textContent,
                        }];
                    }
                } else {
                    const regex = /<span class="collaboration-cursor__caret[^>]*>.*?<\/span>/g;
                    const details = textNode.details.replace( regex, '' ); // Remove the collab cursor
                    const val = TiptapHelper.convertTiptapHtmlToCarota( details, textNode.editor.schema );
                    if ( val ) { // Convertable to carota
                        primaryText.rendering = 'carota';
                        primaryText.content = val;
                        primaryText.html = '';
                    } else {
                        primaryText.rendering = 'tiptapCanvas';
                        primaryText.html = details;
                        primaryText.content = [];
                    }
                }

                // Note: Have to update the shape text editor text
                TiptapDocumentsManagerShapeText
                    .updateTiptpChildEditorNode( this.changeModel.id, shape.id, primaryText, true );

                const { width, height } = TiptapDocumentsManagerShapeText
                    .getTextBounds( this.changeModel.id, shape.id, primaryText.id );
                primaryText.width = width;
                primaryText.height = height;

                // NOTE: Have to update text bounds after above step
            }
        }
    }

    // For tracking purpose only
    /* istanbul ignore next */
    protected trackDescriptionDataitem( id, shape, value = '' ) {
        if ( id === 'description' && !innerText( shape?.data?.description?.value || '' ).trim()) {
            if ( innerText( value ).trim()) {
                Tracker.track( 'text.addnew.click', { value1: 'shape-data' });
            }
        } else if ( id === 'description' ) {
            if ( innerText( value ).trim()) {
                Tracker.track( 'text.edit.change', { value1: 'shape-data' });
            }
        }
    }

    protected updateDataDefs( shapeId: string, dataItemId, data ) {
        const optional = data ? data.optional : false;
        const shape = this.changeModel.shapes[ shapeId ];

        if ( optional && data ) {

            const dataDef = this.changeModel.getInitializedDataSet( shapeId );
            if ( !shape.dataSetId ) {
                shape.dataSetId = Random.dataItemId();
            }
            if ( !this.changeModel.dataDefs[ shape.dataSetId ]) {
                this.changeModel.dataDefs[ shape.dataSetId ] = {};
            }
            const dataItem = dataDef[ dataItemId ];
            if ( dataItem ) { // Updating data item
                Object.assign( dataItem, data );
            } else { // Add
                dataDef[ dataItemId ] = data;
            }
        }

        // Delete def props if shapes are no longer using it.
        const def = this.changeModel.getInitializedDataSet( shapeId );
        if ( def ) {
            const defData = def[ dataItemId ];
            const canDelete = values( this.changeModel.shapes ).filter( s => s.dataSetId ===  shape.dataSetId )
                .length === 1;
            if ( !data && defData && canDelete ) {
                delete def[ dataItemId ];
            }
        }
    }

    private isDescriptionEmpty( value ) {
        return !(
            value &&
            value.ops &&
            value.ops[0] &&
            value.ops[0].insert
        );
    }

}

Object.defineProperty( ChangeShapeDataItems, 'name', {
    value: 'ChangeShapeDataItems',
});
